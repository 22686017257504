import React from 'react';
import { Link } from '@latitude/link';
import Hero from '@/components/Hero/Hero';
import Layout from '@/components/layout';
import Metadata from '@/components/Metadata/Metadata';
import { Box, Flex } from '@/components/Box/Box';
import { CalloutTwoColumn } from '@/components/Callout';
import { Heading3 } from '@/components/Heading/Heading';
import { ALIGN, MARGIN, JUSTIFY_CONTENT, COLOR } from '@/utils/constants';
import utilityHero from '@/images/hero/utility-hero.jpg';

const FindAStore = props => (
  <Layout location={props.location}>
    <main className="navigation-spacer">
      <Metadata
        title="Find a Store"
        description="Check out where your neareast Latitude Financial location is."
        canonical="/credit-cards/find-a-store/"
      />
      <Hero h1="Store Locator" bg={utilityHero} darkBg />
      <FormSection />
      <CalloutTwoColumn
        line1={
          <React.Fragment>
            Got questions for our credit card team?
          </React.Fragment>
        }
        line2={
          <Link variant={COLOR.WHITE} href="https://latitudefs.zendesk.com/hc/en-au">
            Contact us
          </Link>
        }
      />
    </main>
  </Layout>
);

const FormSection = () => (
  <Box.Section backgroundColor={COLOR.GREY5}>
    <Heading3 align={ALIGN.CENTER} color={COLOR.BLUE}>
      0% interest stores
    </Heading3>
    <Flex
      justifyContent={JUSTIFY_CONTENT.CENTER}
      margin={`${MARGIN.M0} ${MARGIN.MAUTO}`}
      isResponsive
    >
      <iframe
        css={`
          width: 100%;
          min-height: 900px;
          border: none;
        `}
        title="store-finder"
        src="//apps.nowwhere.com.au/GE/Locator/app/filters/gemau"
      />
    </Flex>
  </Box.Section>
);

export default FindAStore;
